import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import MDTypography from "components/MDTypography";
import ModalItem from "components/ModalItem";
import { Form, Formik, useFormikContext } from "formik";
import React, { useRef } from "react";
import './../../presentation/scrollbar.css'
import MDButton from "../../../components/MDButton";
import {TASK_DEPENDECY_TYPES} from "../../../services/constants";
import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import DeleteIcon from "@mui/icons-material/Delete";
import SortableList from "../../../components/SortableList";


const dependencyOptions = [
  { id: TASK_DEPENDECY_TYPES.NO_DEPENDENCY, name: "No dependency" },
  { id: TASK_DEPENDECY_TYPES.ON_SUBMIT, name: "On Submit" },
  { id: TASK_DEPENDECY_TYPES.ON_APPROVAL, name: "On Approval" },
]


const TaskGroupViewModal = ({ showModal, handleClose, onCreated, taskGroup }) => {
  const formikRef = useRef();

  const initialValues = {
    name: taskGroup?.name || "",
    tasks: taskGroup?.tasks || [{ name: "", description: "", reward: 0, dependency_type: 0 }],
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Group name is required"),
    tasks: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Task name is required"),
          description: Yup.string(),
          reward: Yup.number().min(0).required("Reward is required"),
          custom_order: Yup.number().min(0).notRequired().nullable(),
        }),
      )
      .min(1, "At least one task is required"),
  })

  const onSubmit = (values) => {
    let valuesCopy = {...values}
    valuesCopy.tasks[0].dependency_type = TASK_DEPENDECY_TYPES.NO_DEPENDENCY
    onCreated(valuesCopy)
    handleClose()
  }

  const handleAddTask = (values, setFieldValue) => {
    const newTasks = [...values.tasks, { name: "", description: "", reward: 0 }]
    setFieldValue("tasks", newTasks)
  }

  const handleRemoveTask = (index, values, setFieldValue) => {
    const newTasks = values.tasks.filter((_, i) => i !== index)
    setFieldValue("tasks", newTasks)
  }


  const renderItemComponent = (item, index, errors, setFieldValue) => (
    <Box flex={1} display="flex" flexDirection="column" gap="10px" bgcolor={"#FFF"}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <FormikInput name={`tasks[${index}].name`} type="text" label="Name" fullWidth />
        <FormikInput
          name={`tasks[${index}].description`}
          type="textarea"
          label="Description"
          fullWidth
          rows={2}
          flex={3}
        />
        <FormikInput
          name={`tasks[${index}].dependency_type`}
          initialValue={dependencyOptions[0]}
          value={index === 0
            ? dependencyOptions[0]
            : dependencyOptions.find((dependency) => dependency.id === item.dependency_type) || dependencyOptions[0]
          }
          type="select"
          options={dependencyOptions}
          onChangeSelect={(e, value) => {
            setFieldValue(`tasks[${index}].dependency_type`, value.id)
          }}
          label="Dependency"
          width={"75px"}
          errors={errors}
          flex={1}
          disabled={index === 0}
        />
        <FormikInput name={`tasks[${index}].reward`} type="number" label="Reward" width={"75px"} />
        <MDButton
          color="secondary"
          variant="outlined"
          sx={{ marginBottom: "18px" }}
          onClick={() =>
            handleRemoveTask(index, formikRef.current.values, formikRef.current.setFieldValue)
          }
        >
          <DeleteIcon />
        </MDButton>
      </Box>
    </Box>
  )

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      title={"Import Task Group"}
      handleClose={handleClose}
      width={"90%"}
      height={"90%"}
      contentContainterStyle={{ width: "90%", height: "90%" }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleSubmit, setFieldValue, isValid, dirty }) => {
          return (
            <Form style={{ width: "100%" }}>
              <Grid container rowGap={"12px"} px={"15px"}>
                <Grid item xs={12} mt={2}>
                  <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} mr={2} mb={1}>
                    Task Group Name
                  </MDTypography>
                  <FormikInput name="name" type="text" label="Task Group Name" fullWidth />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} mr={2}>
                    Tasks
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <SortableList
                    items={values.tasks}
                    errors={errors}
                    setItems={(newItems) => setFieldValue("tasks", newItems)}
                    renderItemComponent={renderItemComponent}
                    itemContainerStyle={{ paddingTop: "5px" }}
                    emptyMessage="No tasks found"
                    dragIconPosition={"left"}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <MDButton
                    color="primary"
                    variant="outlined"
                    onClick={() => handleAddTask(values, setFieldValue)}
                  >
                    Add Task
                  </MDButton>
                </Grid>
                <Grid item xs={12} mt={3} display="flex" justifyContent="center">
                  <MDButton color="primary" onClick={handleSubmit}>
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>

    </ModalItem>
  );
};

export default TaskGroupViewModal;
