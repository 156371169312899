import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AddStudentsModal from "components/courses/AddStudentsModal";
import React, {useState, useEffect} from "react";
import {useApi} from "../../services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import {PopoverActions, PopoverItem} from "../../components/PopoverActions";
import {ENROLMENT_STATUS} from "../../utils/helpers";
import {Grid, Icon, IconButton} from "@mui/material";
import CourseInvitationModal from "../../components/courses/CurseInvitationModal";
import Box from "@mui/material/Box";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import calendarOrder from "../../assets/icons/calendar-order.svg";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {useManualBucksModal} from "../admin-panel/StudentManualBuckModal";

const AdjustBucksButtons = ({sendBucks}) => {
    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"nowrap"}
            px={1}
            // borderRight="3px solid lightgray"
        >
            <IconButton
                color="primary"
                onClick={() => sendBucks(true)}
            >
                <AddRoundedIcon />
            </IconButton>
            <Icon>
                <img src={calendarOrder} alt="thumbs-down" />
            </Icon>
            <IconButton color="error" onClick={() => sendBucks(false)}>
                <RemoveRoundedIcon />
            </IconButton>
        </Box>
    )
}

const CourseStudentList = ({courseId}) => {
    const [addStudentsModal, setAddStudentsModal] = useState(false);
    const [openQRInvitationModal, setOpenQRInvitationModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [datatable, setDatatable] = useState({...dataTableModel});
    const [bucksPresets, setBucksPresets] = useState([]);
    const [plusBucksPresets, setPlusBucksPresets] = useState([])
    const [minusBucksPresets, setMinusBucksPresets] = useState([])
    const api = useApi();

    const getBucksPresets = () => {
        api.getBucksPresets().handle({
            onSuccess: (response) => {
                const presets = response.data.results || []
                setPlusBucksPresets(presets.filter((bp) => !!bp.is_addition ))
                setMinusBucksPresets(presets.filter((bp) => !bp.is_addition ))
            },
            errorMessage: 'Error getting positive bucks presets',
        })
    }


    const [dialog, showModal] = useManualBucksModal({
        sendBucks: (data, values) => {
            api
                .manualBucks(courseId, {
                    student: data.studentId,
                    amount: values.amount,
                    description: values.message,
                    plus: data.plus,
                })
                .handle({
                    successMessage: data.plus ? "Bucks sent" : "Bucks taken",
                    errorMessage: data.plus ? "Error sending bucks" : "Error taking bucks",
                    onFinally: () => {
                        getBucksPresets()
                    }
                });
        },
        bucksPresets: bucksPresets,
    });

    const sendBucks = (item) => {
        return (add) => {
            setBucksPresets(!!add ? plusBucksPresets : minusBucksPresets)
            showModal({
                studentName: item.name,
                studentId: item.student_id,
                plus:add,
            })
            // console.log(item, add)
        }
    }

    const getStudentsEnrolment = () => {
        api.getStudentsEnrolment(courseId).handle({
            onSuccess: (res) => {
                const data = res.data
                const tmp = {...dataTableModel}
                const bucksButtons = (item) => <AdjustBucksButtons sendBucks={sendBucks(item)}/>
                tmp.rows = data.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem, bucksButtons)) || []
                setDatatable(tmp)
            },
            errorMessage: "Error getting students enrolment",
        });
    }

    const acceptStudentEnrolment = (enrolmentId) => {
        api.acceptStudentEnrolment(courseId, enrolmentId).handle({
            onSuccess: (res) => {
                setOpenPopover(false)
            },
            successMessage: "Student accepted",
            errorMessage: "Error accepting student enrolment",
        });
    }

    const denyStudentEnrolment = (enrolmentId) => {
        api.denyStudentEnrolment(courseId, enrolmentId).handle({
            onSuccess: (res) => {
                onClose()
            },
            errorMessage: "Error denying student enrolment",
        });
    }

    const onClose = () => {
        setOpenPopover(false)
        setSelectedItem(null)
    }

    useEffect(() => {
        getBucksPresets()
    }, []);

    useEffect(() => {
        getStudentsEnrolment(courseId);
        const interval = setInterval(() => {
            getStudentsEnrolment(courseId)
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [courseId, addStudentsModal, openPopover]);

    return (
        <>
            {dialog}
            <Grid item container xs={12} display="flex" alignItems="center" mt={3} borderBottom="1px solid lightgrey"
                  pb={1} mb={3}>
                <Grid item xs={5}>
                    <MDTypography color="primary" flex={1} ml={2}>
                        Students
                    </MDTypography>
                </Grid>
                <Grid item textAlign={'right'} display='flex' flexDirection="row" justifyContent='end' xs={7}>
                    <MDBox mr={'10px'}>
                        <MDButton sx={{ml: 2}} onClick={() => setOpenQRInvitationModal(true)}>
                            Invitation code / QR
                        </MDButton>
                    </MDBox>
                    <CourseInvitationModal open={openQRInvitationModal} setOpen={setOpenQRInvitationModal} courseId={courseId}/>
                    <MDBox mr={'16px'}>
                        <MDButton sx={{ml: 2}} onClick={() => setAddStudentsModal(true)}>
                            Send Invitation
                        </MDButton>
                    </MDBox>
                    <AddStudentsModal open={addStudentsModal} setOpen={setAddStudentsModal} courseId={courseId}/>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} paddingRight={2}>
                    <DataTable
                        table={datatable}
                    />
                </Grid>
            </Grid>
            {selectedItem &&
                <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl} horizontal={"left"}>
                    {
                        selectedItem?.status_id === ENROLMENT_STATUS.REQUESTED && <>
                            <PopoverItem label={"Accept"} color="text"
                                         onClick={() => acceptStudentEnrolment(selectedItem.id)}/>
                            <PopoverItem
                                label={selectedItem?.status.props.id === ENROLMENT_STATUS.REQUESTED ? "Deny" : "Withdraw"}
                                color="text" onClick={() => denyStudentEnrolment(selectedItem.id)}/>
                        </>
                    }
                    {selectedItem?.status_id === ENROLMENT_STATUS.INVITED &&
                        <PopoverItem label={"Withdraw"} color="text"
                                     onClick={() => denyStudentEnrolment(selectedItem.id)}/>}

                    {selectedItem?.status_id === ENROLMENT_STATUS.ACCEPTED &&
                        <PopoverItem label={"Remove"} color="text"
                                     onClick={() => denyStudentEnrolment(selectedItem.id)}/>
                    }
                </PopoverActions>
      }
        </>
    );
};

export default CourseStudentList;
