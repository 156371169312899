import { Form, Formik } from "formik"
import {Box, Grid} from "@mui/material"
import FormikInput from "../../../components/FormikInput"
import React, { useEffect, useRef, useState } from "react"
import MDButton from "../../../components/MDButton"
import * as Yup from "yup"
import { useApi } from "../../../services/helpers"
import MDBox from "../../../components/MDBox"
import MDTypography from "../../../components/MDTypography"
import CollapsibleList from "./CollapsibleList"
import { useParams } from "react-router-dom"

const LessonBankConfirm = ({ lessonCourseId, lessonId, cancelLessonImport, onSuccessCallback, isDelete = false }) => {
  const api = useApi()
  const { courseId } = useParams()
  const formikRef = useRef()
  const [lessonBankDetail, setLessonBankDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const { questions = [], task_groups: taskGroups = [] } = lessonBankDetail

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().notRequired(),
  })

  const initialValues = {
    name: lessonBankDetail?.name ?? "",
    description: lessonBankDetail?.description ?? "",
    lesson_id: lessonId,
  }

  const getLessonBankDetail = (courseId, lessonId) => {
    api.getLessonBankDetail(courseId, lessonId).handle({
      onSuccess: (res) => {
        setLessonBankDetail(res.data)
      },
      errorMessage: "Error getting courses",
    })
  }

  const importLesson = (data) => {
    let dataCopy = { ...data }
    dataCopy.id = lessonId
    setLoading(true)
    //
    api.importLessonBank(courseId, dataCopy).handle({
      onSuccess: (result) => {
        !!onSuccessCallback && onSuccessCallback()
        // handleClose()
        // onCreated()
      },
      errorMessage: "Error importing lesson",
      successMessage: "Lesson created successfully!",
      onFinally: () => setLoading(false),
    })
  }

  const deleteLesson = () => {
    api.deleteLesson(courseId, lessonId).handle({
      onSuccess: (result) => {
        !!onSuccessCallback && onSuccessCallback()
        // handleClose()
        // onCreated()
      },
      errorMessage: "Error deleting lesson",
      successMessage: "Lesson deleted successfully!",
      onFinally: () => setLoading(false),
    })
  }

  useEffect(() => {
    if (lessonId && lessonCourseId) {
      getLessonBankDetail(lessonCourseId, lessonId)
    }
  }, [lessonCourseId, lessonId])
  //

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnBlur={false}
      onSubmit={(values) => {
        isDelete ? deleteLesson() : importLesson(values)
      }
    }
    >
      {({ errors, handleSubmit }) => (
        <Form style={{ width: "100%" }}>
          <Grid container>
            <Grid item xs={12} pt={1}>
              {isDelete ?
                  <Box display={"flex"} flexDirection={"column"}>
                    <MDTypography
                        color="dark"
                        fontSize={{xs: "18px", md: "20px"}}
                        flexDirection="row"
                        mr={2}
                    >
                      Name:
                    </MDTypography>
                    <Box sx={{background: '#6a994e24', borderRadius: 2, padding: 1}}>
                      {lessonBankDetail?.name}
                    </Box>
                  </Box>
                  :
                  <FormikInput
                      name="name"
                      placeholder="Name"
                      label="Name"
                      errors={errors}
                      disabled={!isDelete}
                      fullWidth
                  />
              }
            </Grid>
            <Grid item xs={12} pt={1}>
              {isDelete ?
                  <Box display={"flex"} flexDirection={"column"}>
                    <MDTypography
                        color="dark"
                        fontSize={{xs: "18px", md: "20px"}}
                        flexDirection="row"
                        mr={2}
                    >
                      Description:
                    </MDTypography>
                    <Box sx={{background: '#6a994e24', borderRadius: 2, padding: 1}}>
                      {lessonBankDetail?.description}
                    </Box>
                  </Box>
                  :
                  <FormikInput
                      name="description"
                      placeholder="Description"
                      label="Description"
                      errors={errors}
                      readOnly={true}
                      fullWidth
                  />
              }
            </Grid>
            <MDBox display={"flex"} flexDirection={"column"} width={"100%"} gap={3} mt={1}>
              {/* ==================== QUESTIONS ==================== */}
              {questions.length > 0 ?
                <CollapsibleList title="Questions" items={questions} />
                  :
                  <MDTypography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "1.25rem",
                        fontWeight: "medium",
                      }}
                      variant="h6"
                      color="dark"
                      fontWeight="regular"
                      gutterBottom
                  >
                    No questions in this lesson
                  </MDTypography>
              }
              {/* ==================== TASK GROUPS ==================== */}
              {taskGroups.length > 0 ?
                <CollapsibleList title="Task Groups" items={taskGroups} />
                  :
                  <MDTypography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "1.25rem",
                        fontWeight: "medium",
                      }}
                      variant="h6"
                      color="dark"
                      fontWeight="regular"
                      gutterBottom
                  >
                    No task groups in this lesson
                  </MDTypography>
              }
            </MDBox>
            {/**/}
            <MDBox display={"flex"} justifyContent={"center"} width={"100%"} sx={{marginTop: 2, marginBottom: 2}}>
              <MDTypography
                variant="p"
                fontSize={"1rem"}
                color="secondary"
                fontWeight="regular"
                textAlign="center"
              >
                {isDelete && "This will delete the lesson and all its contents. Proceed with delete?"}
                {!isDelete && "This will copy all questions and task groups to a new lesson. Proceed with import?"}
              </MDTypography>
            </MDBox>
            <Grid item xs={12} display="flex" justifyContent="center" gap={2}>
              <MDButton
                color="primary"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                Confirm
              </MDButton>
              <MDButton
                color="secondary"
                loading={loading}
                disabled={loading}
                onClick={cancelLessonImport}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default LessonBankConfirm
