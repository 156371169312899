import {Box, Grid, Icon, IconButton, Typography} from "@mui/material";
import MDBox from "../../components/MDBox";
import {StudentEngagement, StudentsEngagement, StudentUnderstanding, UnderstandingChart} from "./charts";
import React, { useEffect, useState } from "react"
import {StudentRow} from "./StudentTabRow";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import calendarOrder from "../../assets/icons/calendar-order.svg";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {useManualBucksModal} from "./StudentManualBuckModal";
import {useParams} from "react-router-dom";
import {useApi} from "../../services/helpers";
import MDButton from "../../components/MDButton";
import RandomCallModal from "./components/RandomCallModal";

export const StudentsTab = ({ students, setSelectedStudent, selectedStudent, attendanceStatus, balances, questionAnswered, flags, stats }) => {
    const api = useApi()
    const {meetingId} = useParams()
    const [plusBucksPresets, setPlusBucksPresets] = useState([])
    const [minusBucksPresets, setMinusBucksPresets] = useState([])
    const [bucksPresets, setBucksPresets] = useState([])
    const [openRandomCallModal, setOpenRandomCallModal] = useState(false)

    const getBucksPresets = () => {
        api.getBucksPresets().handle({
            onSuccess: (response) => {
                const presets = response.data.results || []
                setPlusBucksPresets(presets.filter((bp) => !!bp.is_addition ))
                setMinusBucksPresets(presets.filter((bp) => !bp.is_addition ))
            },
            errorMessage: 'Error getting positive bucks presets',
        })
    }

    const setManualBucks = (values) => {
        api.manualBucksInMeeting(meetingId, {
            student: values.student_id,
            amount: values.amount,
            description: values.reason,
            plus: values.plus,
            is_random_call: true,
        }).handle({
            successMessage: values.plus ? "Bucks sent" : "Bucks taken",
            errorMessage: values.plus ? "Error sending bucks" : "Error taking bucks",
        })
    }

    const options = [
        {"name": "Course Students", 'who': 'all'},
        {"name":"Present Students", 'who': 'present'},
        {"name":"Tardy Students", 'who': 'tardy'},
        {"name":"Absent Students", 'who': 'absent'},
    ]
    const [dialog, showModal] = useManualBucksModal({ sendBucks: (data, values) => {
            api.manualBucksInMeeting(meetingId, {
                class: true,
                amount: values.amount,
                description: values.message,
                plus: data.plus,
                who: values.selector?.who,
            }).handle({
                successMessage: data.plus ? "Bucks sent" : "Bucks taken",
                errorMessage:data.plus ? "Error sending bucks" : "Error taking bucks",
            })
        }, selectorOptions: options, defaultSelector: options[1], bucksPresets: bucksPresets })

    const sendBucks = (plus) => {
        setBucksPresets(!!plus ? plusBucksPresets : minusBucksPresets)
        //
        showModal({
            studentName: 'Course Students',
            plus,
        })
    }

    useEffect(() => {
        getBucksPresets()
    }, [])
    //
    return (
        <>
            {dialog}
        <Grid container xs={12} mt={3} direction={{xs:'column', md:'row'}} wrap={"nowrap"} columnSpacing={2}>
            <Grid xs={1} order={{xs:'2', md:'1'}} container item  direction="column" wrap={"nowrap"}>
            <Grid item xs={12}>
                {/*<MDBox py={1} width={'100%'}>*/}
                    <Typography color="primary" fontWeight="bold" borderLeft={"5px solid #6A994E"} pl={1}>
                        {selectedStudent ? selectedStudent.name : "Global Statistics"}
                    </Typography>
                {/*</MDBox>*/}
            </Grid>

                {!selectedStudent ? (
                    <Grid  item xs={12} py={2}>
                        <StudentsEngagement stattsData={stats.engagements} />
                        <StudentUnderstanding stattsData={stats.understandings} />
                    </Grid>
                ) : (
                    <Grid item xs={12} py={2}>
                        <UnderstandingChart sx={{mb: 1}} understandingPercentage={20} />
                        <StudentEngagement/>
                    </Grid>
                )}
            </Grid>

            <Grid xs={11} item order={{xs:'1', md:'2'}} direction='column' wrap={"nowrap"}>
                <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} >
                    <Typography color="primary" fontWeight="bold">
                        Attendance List
                    </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                        <MDButton
                          variant="contained"
                          onClick={() => setOpenRandomCallModal(true)}
                          color={"primary"}
                          sx={{mr:1}}
                        >
                            Random Calling
                        </MDButton>
                        <Box display={'flex'} alignItems={'center'} flexWrap={"nowrap"} px={1} borderLeft="3px solid lightgray">
                            <Typography color="secondary" >
                                Class
                            </Typography>
                            <IconButton color="primary"  onClick={() => sendBucks(true)}>
                                <AddRoundedIcon />
                            </IconButton>
                            {/*<IconButton>*/}
                            <Icon>
                                <img src={calendarOrder} alt="thumbs-down" />
                            </Icon>
                            {/*</IconButton>*/}
                            <IconButton color="error" onClick={() => sendBucks(false)}>
                                <RemoveRoundedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>

            <Grid  item xs={9} py={2} >
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" >
                    {(attendanceStatus?.present || []).map((attendance) => (
                        <StudentRow
                            balances={ balances }
                            key={attendance.student.id}
                            student={attendance.student}
                            tardy={attendance.tardy}
                            //setSelectedStudent={setSelectedStudent}
                            isExpanded={selectedStudent?.id === attendance.student.id}
                            onSelect={() => {
                              //  setSelectedStudent(attendance.student.id === selectedStudent?.id ? null : attendance.student);
                            }}
                            flags={flags[attendance.student.user_id] || {}}
                            plusBucksPresets={plusBucksPresets}
                            minusBucksPresets={minusBucksPresets}
                            questionAnswered={questionAnswered}
                        />
                    ))}
                    <Typography color="primary" fontWeight="bold" mt={3}>
                    Absence List
                    </Typography>
                    {(attendanceStatus?.absent || []).map((attendance) => (
                        <StudentRow
                            balances={ balances }
                            absent={true}
                            tardy={false}
                            key={attendance.student.id}
                            student={attendance.student}
                            //setSelectedStudent={setSelectedStudent}
                            isExpanded={selectedStudent?.id === attendance.student.id}
                            onSelect={() => {
                              //  setSelectedStudent(attendance.student.id === selectedStudent?.id ? null : attendance.student);
                            }}
                            flags={{}}
                            plusBucksPresets={plusBucksPresets}
                            minusBucksPresets={minusBucksPresets}
                        />
                    ))}
                </MDBox>

            </Grid>
            </Grid>
        </Grid>
            <RandomCallModal
              meetingId={meetingId}
              showModal={openRandomCallModal}
              handleClose={() => setOpenRandomCallModal(false)}
              plusBucksPresets={plusBucksPresets}
              minusBucksPresets={minusBucksPresets}
              handleConfirm={setManualBucks}
            />
    </>
    );
};
