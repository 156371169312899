import ModalItem from "components/ModalItem"
import MDBox from "../../components/MDBox"
import LessonBankConfirm from "./components/LessonBankConfirm"

const LessonDeleteModal = ({ showModal, currentCourseId, lesson2delete, handleClose, onDeleted }) => {

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="auto"
      title={"Delete Lesson"}
      handleClose={handleClose}
      width={"100%"}
      contentContainterStyle={{width: "80%"}}
    >
      <MDBox width={"100%"} >
        <LessonBankConfirm
          isDelete
          lessonId={lesson2delete?.id}
          lessonCourseId={currentCourseId}
          cancelLessonImport={handleClose}
          onSuccessCallback={() => {
            handleClose()
            !!onDeleted && onDeleted()
          }}
        />

      </MDBox>
    </ModalItem>
  )
}

export default LessonDeleteModal
