import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import {default as React, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {EmptyResponse} from '../../components/EmptyResponse'
import MDButton from '../../components/MDButton'
import {useApi} from '../../services/helpers'
import QuestionImportModal from '../presentation/QuestionImportModal'
import QuestionModal from '../presentation/QuestionModal'
import QuestionViewDetailsModal from '../presentation/QuestionViewDetailsModal'
import IAQuestionModal from './IAQuestionModal'
import InstantMeetingModal from './InstantMeetingModal'
import LessonModal from './LessonModal'
import NewLessonModal from './NewLessonModal'
import {LessonItem} from './components/LessonItem'
import TaskGroupModal from './components/TaskGroupModal'
import {v4 as uuidv4} from "uuid";
import MenuBookIcon from "@mui/icons-material/MenuBook"
import LessonBankModal from "./LessonBankModal"
import LessonDeleteModal from "./DeleteLessonModal";
import TaskGroupImportModal from "./components/TaskGroupImportModal";
import TaskGroupViewModal from "./components/TaskGroupViewDetailsModal";

const TeachersLessons = () => {
  const api = useApi();
  const {courseId} = useParams();
  const [lessons, setLessons] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [showLessonModal, setShowLessonModal] = useState(false);
  const [showNewLessonModal, setShowNewLessonModal] = useState(false);
  const [showDeleteLessonModal, setShowDeleteLessonModal] = useState(false);
  const [showLessonBankModal, setShowLessonBankModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState({
    isVisible: false,
    question: null,
  });
  const [showQuestionImportModal, setShowQuestionImportModal] = useState(false);
  const [showTaskImportModal, setShowTaskImportModal] = useState(false);
  const [showQuestionViewModal, setShowQuestionViewModal] = useState({
    isVisible: false,
    question: null,
    viewMode: false,
    deleteMode: false,
  })
  const [showTaskGroupViewModal, setShowTaskGroupViewModal] = useState({
    isVisible: false,
    taskGroup: null,
    viewMode: false,
    deleteMode: false,
  })
  const [showInstantMeetingModal, setShowInstantMeetingModal] = useState(false)
  const [showAIQuestionModal, setShowAIQuestionModal] = useState(false)

  const [refreshQuestionsLessonId, setRefreshQuestionsLessonId] = useState(0)
  const [refreshTaskGroupsLessonId, setRefreshTaskGroupsLessonId] = useState(0)
  //
  const [showTaskGroupModal, setShowTaskGroupModal] = useState({
    isVisible: false,
    taskGroup: null,
  })

  const createCourseLessonTaskGroup = (data) => {
    api.createCourseLessonTaskGroup(courseId, selectedLesson.id, data).handle({
      onSuccess: (res) => {
        setShowTaskGroupModal({isVisible: false, taskGroup: null})
        setRefreshTaskGroupsLessonId(selectedLesson.id)
      },
      errorMessage: 'Error creating task group',
      successMessage: 'Task group created successfully!',
    })
  }

  const updateCourseLessonTaskGroup = (data) => {
    api
      .updateCourseLessonTaskGroup(
        courseId,
        selectedLesson.id,
        showTaskGroupModal.taskGroup?.id,
        data
      )
      .handle({
        onSuccess: (res) => {
          setShowTaskGroupModal({isVisible: false, taskGroup: null})
          setRefreshTaskGroupsLessonId(selectedLesson.id)
        },
        errorMessage: 'Error updating task group',
        successMessage: 'Task group updated successfully!',
      })
  }

  const createCourseLessonQuestion = (data) => {
    api.createCourseLessonQuestion(courseId, selectedLesson.id, data).handle({
      onSuccess: (res) => {
        setShowQuestionModal({isVisible: false, question: null})
        setRefreshQuestionsLessonId(selectedLesson.id)
        handleCloseModalViewTaskGroup(false)
      },
      errorMessage: "Error creating question",
      successMessage: "Question created successfully!",
    });
  };

  const updateCourseLessonQuestion = (data) => {
    api
      .updateCourseLessonQuestion(courseId, selectedLesson.id, showQuestionModal.question?.id, data)
      .handle({
        onSuccess: (res) => {
          setShowQuestionModal({isVisible: false, question: null});
          setRefreshQuestionsLessonId(selectedLesson.id)
        },
        errorMessage: "Error creating question",
        successMessage: "Question updated successfully!",
      });
  };

  const getLessonsByCourse = () => {
    api.getLessonsByCourse(courseId).handle({
      onSuccess: (res) => {
        setLessons(res.data.results);
      },
      errorMessage: "Error getting lessons",
    });
  };

  const updateLesson = (values) => {
    api.updateLesson(courseId, selectedLesson.id, values).handle({
      onSuccess: (res) => {
        setSelectedLesson(null);
        setShowLessonModal(false);
      },
      successMessage: "Lesson updated",
      errorMessage: "Error updating lesson",
    });
  };

  const handleCloseModalQuestion = () => {
    //setSelectedLesson(null)
    setShowQuestionModal({isVisible: false, question: null})
  }

  const handleCloseTaskGroupModal = () => {
    //setSelectedLesson(null)
    setShowTaskGroupModal({isVisible: false, question: null})
  }

  const handleCloseModalImportQuestion = () => {
    //setSelectedLesson(null);
    setShowQuestionImportModal(false)
  };

  const handleCloseModalImportTask = () => {
    setShowTaskImportModal(false)
  }

  const handleCloseModalViewQuestion = (updateQuestions = false) => {
    if (updateQuestions) {
      setRefreshQuestionsLessonId(showQuestionViewModal.question.lesson_id)
    }
    setShowQuestionViewModal({isVisible: false, question: null});
  };

  const handleCloseModalViewTaskGroup = (updateTaskGroups = false) => {
    if (updateTaskGroups) {
      setRefreshTaskGroupsLessonId(showTaskGroupViewModal.taskGroup.lesson_id)
    }
    setShowTaskGroupViewModal({isVisible: false, taskGroup: null});
  }

  useEffect(() => {
    getLessonsByCourse();
  }, [selectedLesson]);

  const handleAddQuestion = (question) => {
    setShowQuestionModal({
      isVisible: true,
      question: {...question, id: null, answers: question.answers?.map(answer => ({...answer, tempId: uuidv4()}))},
    });
  };

  return (
    <MDBox width={"100%"}>
      <MDBox
        display="flex"
        justifyContent={"space-around"}
        py={1}
        mb={3}
        gap={1}
        width={"100%"}
        borderBottom="1px solid rgba(0, 0, 0, 0.25)"
      >
        <MDTypography
          flex={1}
          fontSize={{xs: "24px", md: "30px"}}
          variant="h3"
          color="primary"
          fontWeight="regular"
        >
          Lessons
        </MDTypography>
        <MDButton
          variant="contained"
          onClick={() => {
            setShowLessonBankModal(true);
          }}
          size="small"
          sx={{boxShadow: 2, "&:hover": {boxShadow: 3}, mb: 1}}
        >
          <MDTypography
            sx={{textWrap: "nowrap"}}
            variant="caption"
            color="secondary"
            fontWeight="bold"
            mr={1}
          >
            Lesson Bank
          </MDTypography>
          {/**/}
          <MenuBookIcon sx={{marginBottom: 0.2}}/>
        </MDButton>
        <MDButton
          variant="contained"
          onClick={() => {
            setShowNewLessonModal(true);
          }}
          size="small"
          sx={{boxShadow: 2, "&:hover": {boxShadow: 3}, mb: 1}}
        >
          <MDTypography
            sx={{textWrap: "nowrap"}}
            variant="caption"
            color="secondary"
            fontWeight="bold"
          >
            Add Lesson +
          </MDTypography>
        </MDButton>
      </MDBox>
      {/* ============ LESSONS ============ */}
      <MDBox display={'flex'} flexDirection={'column'} gap={2}>
        {lessons?.length > 0 ? (
          lessons.map((lesson) => (
            <LessonItem
              key={lesson.id}
              lesson={lesson}
              setSelectedLesson={setSelectedLesson}
              setShowLessonModal={setShowLessonModal}
              setShowQuestionModal={setShowQuestionModal}
              setShowQuestionImportModal={setShowQuestionImportModal}
              showQuestionModal={showQuestionModal}
              setShowQuestionViewModal={setShowQuestionViewModal}
              setShowTaskImportModal={setShowTaskImportModal}
              setShowTaskGroupViewModal={setShowTaskGroupViewModal}
              setShowInstantMeetingModal={setShowInstantMeetingModal}
              setShowTaskGroupModal={setShowTaskGroupModal}
              setShowDeleteLessonModal={setShowDeleteLessonModal}
              refreshQuestionsLessonId={refreshQuestionsLessonId}
              setRefreshQuestionsLessonId={setRefreshQuestionsLessonId}
              refreshTaskGroupsLessonId={refreshTaskGroupsLessonId}
              setRefreshTaskGroupsLessonId={setRefreshTaskGroupsLessonId}
              setShowAIQuestionModal={setShowAIQuestionModal}
            />
          ))
        ) : (
          <EmptyResponse text={"No lessons found"} height={"5vh"}/>
        )}
      </MDBox>
      {/* ============ MODALS ============ */}
      <LessonModal
        handleClose={() => setShowLessonModal(false)}
        showModal={showLessonModal}
        onSave={updateLesson}
        lesson={selectedLesson}
      />
      <QuestionModal
        handleClose={handleCloseModalQuestion}
        showModal={showQuestionModal.isVisible}
        onCreated={createCourseLessonQuestion}
        onUpdate={updateCourseLessonQuestion}
        question={showQuestionModal.question}
      />
      <QuestionImportModal
        handleClose={handleCloseModalImportQuestion}
        showModal={showQuestionImportModal}
        selectedLesson={selectedLesson}
        setShowQuestionModal={setShowQuestionModal}
        setShowQuestionViewModal={setShowQuestionViewModal}
        showQuestionModal={showQuestionModal} // for refreshing the question list
      />
      <TaskGroupImportModal
        handleClose={handleCloseModalImportTask}
        showModal={showTaskImportModal}
        selectedLesson={selectedLesson}
        setShowTaskGroupViewModal={setShowTaskGroupViewModal}
        setShowTaskGroupModal={setShowTaskGroupModal}
      />
      <QuestionViewDetailsModal
        handleClose={handleCloseModalViewQuestion}
        showModal={showQuestionViewModal.isVisible}
        question={showQuestionViewModal.question}
        viewMode={showQuestionViewModal.viewMode}
        deleteMode={showQuestionViewModal.deleteMode}
      />
      <TaskGroupViewModal
        handleClose={handleCloseModalViewTaskGroup}
        showModal={showTaskGroupViewModal.isVisible}
        taskGroup={showTaskGroupViewModal.taskGroup}
        onCreated={createCourseLessonTaskGroup}
      />
      <TaskGroupModal
        handleClose={handleCloseTaskGroupModal}
        showModal={showTaskGroupModal.isVisible}
        onCreated={createCourseLessonTaskGroup}
        onUpdate={updateCourseLessonTaskGroup}
        taskGroup={showTaskGroupModal.taskGroup}
      />
      <LessonBankModal
        showModal={showLessonBankModal}
        courseSelected={courseId}
        handleClose={() => setShowLessonBankModal(false)}
        onCreated={() => getLessonsByCourse()}
      />
      <NewLessonModal
        showModal={showNewLessonModal}
        courseSelected={courseId}
        handleClose={() => setShowNewLessonModal(false)}
        onCreated={() => getLessonsByCourse()}
      />
      <LessonDeleteModal
        showModal={showDeleteLessonModal}
        currentCourseId={courseId}
        lesson2delete={selectedLesson}
        handleClose={() => setShowDeleteLessonModal(false)}
        onDeleted={() => getLessonsByCourse()}
      />
      <InstantMeetingModal
        showModal={showInstantMeetingModal}
        courseSelected={courseId}
        lessonSelected={selectedLesson}
        handleClose={() => setShowInstantMeetingModal(false)}
        onCreated={() => getLessonsByCourse()}
      />
      <IAQuestionModal
        showModal={showAIQuestionModal}
        courseSelected={courseId}
        lessonSelected={selectedLesson}
        handleClose={() => setShowAIQuestionModal(false)}
        onAdd={handleAddQuestion}
      />
    </MDBox>
  )
}

export default TeachersLessons
