import defaultImg from "../../assets/images/default-profile.png";
import Box from "@mui/material/Box";
import {Chip, Icon, IconButton} from "@mui/material";
import { ENROLMENT_STATUS } from "../../utils/helpers";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import calendarOrder from "../../assets/icons/calendar-order.svg";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import React from "react";

const STATUS_OPTIONS_COLORS = {
  [ENROLMENT_STATUS.INVITED]: "warning",
  [ENROLMENT_STATUS.REQUESTED]: "warning",
  [ENROLMENT_STATUS.ACCEPTED]: "success",
  [ENROLMENT_STATUS.REJECTED]: "error",
  [ENROLMENT_STATUS.WITHDRAWN]: "secondary",
}

export const dataTableModel = {
  columns: [
    {Header: "Profile Picture", accessor: "profile_picture", disableOrdering: true, width:60},
    {Header: "Name", accessor: "name"},
    {Header: "Email", accessor: "email"},
    {Header: "Balance", accessor: "balance", width:70},
    {Header: "Adjust balance", accessor: "bucks", disableOrdering: true, width:70},
    {Header: "Status", accessor: "status", width:100},
    {Header: "Actions", accessor: "actions", disableOrdering: true, width:60}
  ],
  rows: [],
};

const renderProfilePicture = (item) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      {item.image
        ? <Box component={"img"} src={item.image} alt={"profile_picture"} width={"40px"}
               sx={{objectFit: 'cover'}} borderRadius={"50%"}/>
        : <Box component={"img"} src={defaultImg} alt={"profile_picture"} width={"40px"}
               borderRadius={"50%"}/>
      }
      {/*{item.name}*/}
    </Box>
  )
}

const renderStatus = (item) => {
  return (
    <Chip id={item.status} color={STATUS_OPTIONS_COLORS[item.status]} label={ENROLMENT_STATUS._LABELS[item.status]} />
  )
}

const popOver = (status, item, setAnchorEl, setOpenPopover, setSelectedItem, extra_jsx) => {
  return (
    <>
        {extra_jsx}
      {
        (status === ENROLMENT_STATUS.INVITED || status === ENROLMENT_STATUS.REQUESTED || status === ENROLMENT_STATUS.ACCEPTED ) &&
        <Icon
          fontSize='medium' sx={{cursor: 'pointer'}}
          onClick={(e) => {
            setAnchorEl(e.currentTarget)
            setOpenPopover(true)
            setSelectedItem(item)
          }}
        >
          more_vert
        </Icon>
      }
    </>
  )
}

export const renderTableRow = (item, setAnchorEl, setOpenPopover, setSelectedItem, bucksButtons) => {
  const status =  item.status
  item.profile_picture = renderProfilePicture(item)
  item.status_id = item.status
  item.status = renderStatus(item)
  item.actions = (popOver(status, item, setAnchorEl, setOpenPopover, setSelectedItem))
  item.bucks = item.student_id ? bucksButtons(item) : '-'
  return item
}
