import React, {useEffect, useState} from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../services/constants";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { useScreenOrientation } from "../../utils/helpers";
import {showMessage, useApi} from "../../services/helpers";
import {IMaskInput} from "react-imask";
import {Input} from "@mui/material";
import MDInput from "../../components/MDInput";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="000-000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
            style={{textAlign: "center"}}
        />
    );
});

export const QrReaderTest = (props) => {
  const navigate = useNavigate();
  const orientation = useScreenOrientation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const api = useApi();

    const markAttendance = (qrcode, code) => {
        setLoading(true)
        api.markAttendance(qrcode, code).handle({
            onSuccess: (res) => {
                navigate(ROUTES.GAME_VIEW(res?.data?.mid))
            },
            successMessage: "Entering into class...",
            errorMessage: "Error entering the class",
            onFinally: () => setLoading(false)
        })
    }

    useEffect(() => {
        if(code && code.length === 7){
            markAttendance(undefined, code.replace('-', ''))
        }
    }, [code]);

  return (
    <MDBox mt={2}>
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >

        <MDTypography variant={"h3"} p={1}>
          Please scan the QR
          <br /> to enter the class
        </MDTypography>
      </MDBox>
      <MDBox display={"flex"} justifyContent={"center"} alignItems={"flex-start"}>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
                if (!loading){
                    // Create a URL object from the result variable
                    try {
                        const url = new URL(result?.text);
                        const searchParams = new URLSearchParams(url.search);
                        const code = searchParams.get('ac');
                        if (!!code) {
                            markAttendance(code, null)
                        }
                    }catch (e){
                        showMessage("Invalid code scanned")
                    }
                }
            }
            if (!!error) {
              console.info(error);
            }
          }}
          containerStyle={{ width: orientation === "portrait-primary" ? "100%" : "40%" }}
          videoContainerStyle={{ width: "100%", height: "100%", display: "flex", paddingTop: 0 }}
          videoStyle={{ position: "relative" }}
        />
      </MDBox>
        <MDBox
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
        >

            <MDTypography variant={"h3"} p={1}>
                Or enter the code below
            </MDTypography>
        </MDBox>
        <MDBox
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Input
                placeholder={"000-000"}
                onChange={(ev) => setCode(ev.target.value)}
                inputComponent={TextMaskCustom}
                disableUnderline
                style={{
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "5px",
                    fontSize: "1.5rem",
                }}
            />
        </MDBox>
    </MDBox>
  );
};
